import Api from "./Api";

export default {
  Login(payload) {
    return Api().post("/api/cinema/sign-in", payload);
  },
  Register(payload) {
    return Api().post("/api/cinema/sign-up", payload);
  },
  ContactusMail(payload) {
    return Api().post("/api/external/mail", payload);
  },
  GetUserHistory(payload) {
    return Api().post("/api/cinema/user-history", payload);
  },
  UpdateUser(payload) {
    return Api().post(`/api/cinema/update-user`, payload);
  },
  ForgotPassword(payload) {
    return Api().post(`/api/external/website-forgot-password`, payload);
  },
  VerifyOTP(payload) {
    return Api().post(`/api/external/website-verify-otp`, payload);
  },
  ResetPassword(payload) {
    return Api().post(`/api/external/website-change-password`, payload);
  },
  LoginViaOtp(payload) {
    return Api().post(`/api/external/website-login-via-otp`, payload);
  },
};
