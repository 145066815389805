import store from "@store/index";
import moment from "moment";

let content_languages = null;

function getContentLanguages() {
  if (!content_languages) {
    const state = store.getState();
    const globalOptions = state.movies.globalOptions || [];

    let content_languages_str = globalOptions.find(
      (x) => x.go_key === "KIOSK_LANGUAGE"
    )?.go_value;

    if (content_languages_str) {
      let content_languages_temp;

      try {
        content_languages_temp = JSON.parse(content_languages_str);

        if (content_languages_temp && Array.isArray(content_languages_temp)) {
          content_languages = content_languages_temp;
        }
      } catch (err) {
        console.log("Error when parsing kiosk content languages: ", err);
      }
    }
  }

  return content_languages;
}

export function getLangSpecificAttribute(
  languageSpecificImages,
  target_lang_id,
  attr,
  lang_id_attr = "content_lang_id",
  isObject = false,
  nestedKey = null
) {
  if (!languageSpecificImages || languageSpecificImages.length === 0)
    return null;

  let target_obj = languageSpecificImages.find(
    (x) => x[lang_id_attr] === target_lang_id
  );

  if (
    target_obj &&
    target_obj[attr] &&
    (!Array.isArray(target_obj[attr]) ||
      (Array.isArray(target_obj[attr]) && target_obj[attr].length > 0)) &&
    (!isObject || (isObject && target_obj[attr][nestedKey]))
  ) {
    return target_obj[attr];
  }

  let content_langs = getContentLanguages();

  if (content_langs && content_langs.length > 0) {
    for (let content_lang of content_langs) {
      let lang_obj = getLangSpecificObject(
        languageSpecificImages,
        content_lang?.lang_name
      );
      if (lang_obj && lang_obj[attr]) {
        return lang_obj[attr];
      }
    }
  } else {
    return null;
  }

  return null;
}

function getLangSpecificObject(arr, language = "") {
  if (arr && arr.length > 0) {
    let obj = arr.find(
      (x) => x.lang_name && x.lang_name.toLowerCase() === language.toLowerCase()
    );

    if (obj) return obj;
    else return null;
  } else {
    return null;
  }
}

export function getFormattedDate(date, format){
  return moment( !!date ? date : moment()).format(format)
}
