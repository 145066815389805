import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CountryCodeDropdown from "../partials/CountryCodeDropdown";
import {
  Row,
  Nav,
  Tabs,
  Tab,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
  Button,
} from "react-bootstrap";
import redoEdit from "@assets/pictures/svgs/redo-edit.svg";
import { updateUser } from "../../store/auth/authActions";
import { withNamespaces } from "react-i18next";

const EditAccount = ({ setToggleEdit, ...props }) => {
  const { t } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [editable, setEditable] = useState(false);
  const [lastName, setLastName] = useState(``);
  const [password, setPassword] = useState(``);
  const [phone_no, setPhone_no] = useState(``);
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser } = loginDetails;
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  useEffect(() => {
    if (loggedInUser) {
      setFirstName(loggedInUser.first_name);
      setLastName(loggedInUser.last_name);
      setEmail(loggedInUser.cust_email);
      setPhone_no(loggedInUser.cust_mobile);
    }
  }, [loggedInUser]);
  const onClickUpdateUser = () => {
    dispatch(
      updateUser({
        first_name: firstName,
        last_name: lastName,
        callback: setToggleEdit,
        email,
        phone_no,
      })
    );
    setEditable(false);
  };

  return (
    <>
      <div className="custom-form">
        <div>
          <label>{t("login_form.First Name")}</label>
          <input
            type="text"
            className="form-control"
            value={firstName}
            required
            onChange={(e) => {
              setFirstName(e.target.value);
              setEditable(true);
            }}
          />
        </div>
        <div>
          <label>{t("login_form.Last Name")}</label>
          <input
            type="text"
            className="form-control"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              setEditable(true);
            }}
          />
        </div>
        <div>
          <label>{t("Email")}</label>
          <input
            type="text"
            className="form-control"
            value={email}
            required
            disabled
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>{t("login_form.Phone Number")}</label>
          <div className="d-flex">
            {/* <CountryCodeDropdown
              className="form-control mr-2"
              onSelectCountry={({ country_id, country_mob_code }) => {
                setCountryId(country_id);
                setCountryMobCode(country_mob_code);
              }}
            /> */}
            {loggedInUser.country_mob_code && (
              <input
                type="number"
                name=""
                id=""
                value={loggedInUser.country_mob_code}
                className="form-control mr-2 w-25"
                disabled
              />
            )}
            <input
              type="text"
              className="form-control"
              value={phone_no}
              required
              disabled
              min="10"
              onChange={(e) => setPhone_no(e.target.value)}
            />
          </div>
        </div>
      </div>
      {editable && (
        <div className="d-flex justify-content-center align-items-center mb-4 mb-md-5 flex-column flex-md-row">
          <button
            className="btn-main mr-md-4 mb-3 mb-md-0"
            onClick={() => onClickUpdateUser()}
          >
            {t("Confirm")}
          </button>
          <button
            className="btn-basic"
            onClick={() => {
              setToggleEdit(false);
              setEditable(false);
            }}
          >
            {capitalizeFirstLetter(t("common.CANCEL"))}
          </button>
        </div>
      )}
    </>
  );
};

const UserSettings = (props) => {
  //Start Edit Account
  //End Edit Account
  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser } = loginDetails;
  const [toggleEdit, setToggleEdit] = useState(false);
  const { t } = props;
  return (
    <div className="user-settings">
      <div className="header">
        <h5 className="font-weight-bold">{t(`User Settings`)}</h5>
        {/* <img
          src={redoEdit}
          alt="redo-edit"
          className="redo-icon"
          onClick={() => setToggleEdit((prev) => !prev)}
        /> */}
      </div>
      <div className="content">
        {/* <div>
          <p>
            {t("login_form.First Name")} {t("login_form.Last Name")}
          </p>
          <p>
            {loggedInUser?.first_name} {loggedInUser?.last_name}
          </p>
        </div>
        <div>
          <p>{t("Email")}</p>
          <p>{loggedInUser?.cust_email}</p>
        </div>
        <div>
          <p>{t("login_form.Phone Number")}</p>
          <p>{loggedInUser?.cust_mobile}</p>
        </div> */}
        {/* <div className="close-account text-right">
          <span className="text-primary font-weight-bold">
            Close my account
          </span>
        </div> */}
        {/* {toggleEdit && ( */}
        <EditAccount t={t} setToggleEdit={setToggleEdit} />
        {/* )} */}
      </div>
    </div>
  );
};

export default withNamespaces()(UserSettings);
