import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "@assets/pictures/svgs/close.svg";
import screenSl from "@assets/pictures/svgs/screen-sl.svg";
import standardSeat from "@assets/pictures/svgs/seat-standard.svg";
import selectedSeat from "@assets/pictures/svgs/seat-standard-selected.svg";
import unavailableSeat from "@assets/pictures/svgs/seat-standard-unavailable.svg";
import slEntrance from "@assets/pictures/svgs/sl-entrance.svg";
import slEntranceDual from "@assets/pictures/svgs/sl-entrance-dual.svg";

import { slImageOnhover } from "../../../helper/formatting.js";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../Spinner";

export default function SeatTypePopup(props) {
  const { loader } = props;
  useEffect(slImageOnhover);
  const seatLayout = useSelector((state) => state.ancilliary.audiSeatsLayout);
  useEffect(() => {
    console.log("seatLayout", seatLayout);
  }, [seatLayout]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="seat-layout-popup"
      centered
      onHide={() => props.closeFunc(false)}
    >
      <section className="wrapper container-fluid">
        {loader ? (
          <div className="row my-5">
            <div className="col-12 text-center">
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <img
              src={close}
              className="close-btn-main"
              onClick={() => props.closeFunc(false)}
              alt=""
            />

            <section className="row title-row">
              <div className="col-12">
                <p className="title">
                  {seatLayout &&
                    seatLayout.screenDetails &&
                    seatLayout.screenDetails.screen_name}
                </p>
              </div>
            </section>

            <section className="row">
              <div className="px-3 mx-auto">
                <div className="seat-layout-screen">
                  <img src={screenSl} className="img-fluid" alt="" />
                </div>
              </div>
            </section>

            <section className="row">
              <div className="col">
                <div className="table-responsive">
                  <table className="master-table table table-borderless">
                    {[1].map((index) => (
                      <tr key={index}>
                        <td className="category">
                          <tr>
                            {/* <td className="category-price">
                          <p>standard</p>
                          <p>$40</p>
                        </td>

                        <td className="category-range"></td> */}

                            <table className="actual-table">
                              <tr>
                                <td>
                                  <table>
                                    {seatLayout &&
                                      seatLayout.seatName &&
                                      seatLayout.seatName.map((name, index) => (
                                        <tr key={index}>
                                          <td className="">
                                            <div className="cell">
                                              {name.includes("XXX-")
                                                ? ""
                                                : name}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </table>
                                </td>
                                <td>
                                  <table>
                                    {seatLayout &&
                                      seatLayout.seats &&
                                      seatLayout.seats.map((seatrow, index) => (
                                        <tr key={index}>
                                          {seatrow.map((row, index) => (
                                            <td className="" key={index}>
                                              {row.sl_seat_name == "" ? (
                                                <div className="cell"></div>
                                              ) : (
                                                <div className="cell seat"></div>
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ))}
                                  </table>
                                </td>
                                {/* <td>
                              <table>
                                {[1, 2, 3, 4, 5].map((index) => (
                                  <tr>
                                    <td className="">
                                      <div className="cell">{index}</div>
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td> */}
                              </tr>
                            </table>
                          </tr>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      {/* <td colSpan="3">
                    <div className="seat-type">
                      <p className="seat-type">
                        standard
                        <span className="hover-image-span">
                          <img src={infoCircle} id="hover-image" />
                          <div className="seat-type-image">
                            <img src={cinemaBanner} />
                          </div>
                        </span>
                      </p>
                    </div>
                  </td> */}
                    </tr>
                  </table>
                </div>
              </div>
            </section>

            {false && (
              <section className="row">
                <div className="px-3 mx-auto entrance">
                  <div className="">
                    <img src={slEntranceDual} alt="" />
                  </div>
                  <div className="">
                    <img src={slEntrance} alt="" />
                  </div>
                  <p>Entrance</p>
                </div>
              </section>
            )}

            <section className="row">
              <div className="px-3 seat-layout-icons">
                <p className="">
                  <img src={standardSeat} className="mr-3" alt="" />
                  Available
                </p>
                <p className="">
                  <img src={unavailableSeat} className="mr-3" alt="" />
                  Unavailable
                </p>
                {/* <p>
                  <img src={selectedSeat} className="mr-3" alt="" />
                  Selected
                </p> */}
              </div>
            </section>
          </>
        )}
      </section>
    </Modal>
  );
}
