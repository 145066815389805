import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { sortSeatsString } from "../../store/auth/authActions";
import useIsMobile from "../../customHooks/useIsMobile";
import DropDownArrow from "@assets/pictures/svgs/dropdown-arrow.svg";
import { useLocation } from "react-router-dom";

const MovieFooter = ({
  movie_title,
  image,
  lang,
  format,
  rating,
  genre,
  cinemaName,
  cine_address,
  cine_location,
  date,
  time,
  showType,
  screeningType,
  totalAmount,
  curr_code,
  booking_type,
  category_id,
  onClickMethod,
  loader,
  enabled,
  showConfirmButton,
  t,
}) => {
  const { pathname } = useLocation();
  const newSeatsString = () => {};
  let seats = useSelector((state) => state.seatLayout.seats_selected);
  const seatString = seats.map((seat) => seat.sl_seat_name);
  const isMobile = useIsMobile();
  const [footerAccordion, setFooterAccordion] = useState(false);

  function footerAccordionHandler() {
    console.log(footerAccordion);
    if (window.innerWidth < 768) {
      setFooterAccordion(true);
    } else if (window.innerWidth >= 768) {
      setFooterAccordion(false);
    }
  }

  window.addEventListener("resize", footerAccordionHandler);
  useEffect(footerAccordionHandler, []);
  return (
    <div className="movie-footer">
      <div className="container-fluid">
        <section className="row">
          <div className="sect">
            {!footerAccordion && (
              <div className="col-md-4 mb-2 mb-md-0">
                <div className="movie-img-n-details">
                  <div className="img-wrapper">
                    <img
                      src={image}
                      alt=""
                      // style={{ width: "60%" }}
                    />
                  </div>
                  <div>
                    <p className="movie-title">{movie_title}</p>
                    <div className={isMobile ? "row mx-0" : ""}>
                      <p
                        className={`movie-details ${
                          isMobile ? "pl-0 pr-1 pr-md-0 col-6 col-md-12" : ""
                        }`}
                      >
                        {lang} {format}
                      </p>
                      <p
                        className={`movie-details ${
                          isMobile ? "pr-0 pl-1 pl-md-0 col-6 col-md-12" : ""
                        }`}
                      >
                        <span>{genre} | </span>
                        <span>{rating}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!footerAccordion && (
              <div
                className={`col-md-3 mb-2 mb-md-0 ${
                  isMobile ? "inline-fields" : ""
                }`}
              >
                {(!pathname.startsWith("/create-show-seat-layout") ||
                  !pathname.startsWith("/seat-layout")) && (
                  <div className="">
                    <p className="movie-field-title">
                      {date && `${t("Date")}`} {time && `& ${t("Time")}:`}
                    </p>
                    <p className="movie-field">
                      {moment(date).format("ll")}
                      {time && `,`}
                      &nbsp;{time && moment(time, "HH:mm:ss").format("HH:mm")}
                    </p>
                  </div>
                )}
                <div className="">
                  {cinemaName && cinemaName !== "All" && (
                    <>
                      <p className="movie-field-title">{t("Cinema")}:</p>
                      <p className="movie-field">
                        {cinemaName}, {cine_address}
                        {/* {cine_location}, */}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="col-6 col-md-2">
              {category_id == 1 && (
                <>
                  <p className="movie-field-title d-none d-md-block">
                    {t("Screening Type")}:
                  </p>
                  {screeningType && (
                    <p className="movie-field d-none d-md-block">
                      {t(screeningType)}
                    </p>
                  )}
                </>
              )}

              {/* {showType && (
                <>
                  <p className="movie-field-title">Show:</p>
                  <p className="movie-field">{showType}</p>
                </>
              )} */}
              <>
                {/* {booking_type != 3 && seats && seats.length > 0 && ( */}
                {footerAccordion ? (
                  pathname.startsWith("/create-show-seat-layout") ||
                  pathname.startsWith("/seat-layout") ? (
                    <p className="movie-field-title">
                      {seats.length > 0 ? t("Picked Seats") : t("Pick Seats")}{" "}
                      {seats.length > 0 && `(${seats?.length})`}:
                    </p>
                  ) : (
                    <div className="">
                      <p className="movie-field-title">
                        {date && `${t("Date")}`} {time && `& ${t("Time")}:`}
                      </p>
                      <p className="movie-field">
                        {moment(date).format("ll")}
                        {time && `,`}
                        &nbsp;{time && moment(time, "HH:mm:ss").format("HH:mm")}
                      </p>
                    </div>
                  )
                ) : (
                  <p className="movie-field-title">
                    {seats.length > 0 ? t("Picked Seats") : t("Pick Seats")}{" "}
                    {seats.length > 0 && `(${seats?.length})`}:
                  </p>
                )}
                {/* )} */}
                {booking_type != 3 && seats && seats.length > 0 && (
                  <p className="movie-field">
                    {seatString.sort(sortSeatsString).map((seat, index) => (
                      <span className="coma" key={index}>
                        {seat}
                        {/* {seats.length - 1 !== index && ","} */}
                      </span>
                    ))}
                  </p>
                )}
                {booking_type == 3 && (
                  <p>
                    <p className="movie-field">{t("common.All")}</p>
                  </p>
                )}
              </>
            </div>
            {/* {showConfirmButton && ( */}
            <div className="col-6 col-md-3 for-ft">
              {/* <p className="movie-field-title">{t("Total Amount")}:</p>
              {
                <>
                  <p className="total-price mb-1">
                    {curr_code} {totalAmount ? totalAmount : t("TBD")}{" "}
                    <span className="movie-field-title">
                      ({t("Amount inclusive of all taxes")})
                    </span>
                  </p>
                </>
              } */}
              {/* <p className="movie-field-title">
                ({t("Amount inclusive of all taxes")})
              </p> */}

              {(pathname.startsWith("/seat-layout") ||
                pathname.startsWith("/create-show-seat-layout")) && (
                <button
                  onClick={() => onClickMethod()}
                  className={`btn-main footer ${!enabled && "inactive"}`}
                >
                  {t("Confirm")}
                </button>
              )}
              {isMobile && (
                <div className="footer-toggle">
                  <figure
                    className={`${!footerAccordion ? "ft-rotate" : ""}`}
                    onClick={() => {
                      footerAccordion
                        ? setFooterAccordion(false)
                        : setFooterAccordion(true);
                    }}
                  >
                    <img src={DropDownArrow} alt="" />
                  </figure>
                </div>
              )}
            </div>
            {/* )} */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default withNamespaces()(MovieFooter);
