import React, { useEffect, useState, useRef, useContext } from "react";
import { withNamespaces } from "react-i18next";
import { Row, Container, Col, Dropdown, Modal, Alert } from "react-bootstrap";
import cinemaBg from "@assets/pictures/cinema-banner.png";
import cinema from "@assets/pictures/cinema.png";
import whiteArrowDown from "@assets/pictures/svgs/white-arrow-down.svg";
import map from "@assets/pictures/svgs/map.svg";
import Slider from "react-slick";
import AuditoriumCard from "./partials/AuditoriumCard.jsx";
import AncilliaryHeroSection from "./partials/AncilliaryHeroSection.jsx";
import { fetchCinemas } from "../store/movies/movieActions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { fetchAllCinemas, selectedCinema } from "@store/cinemas/cinemasActions";
import Spinner from "./partials/Spinner";

const CinemaListing = ({ t }) => {
  const dispatch = useDispatch();
  const cinemas = useSelector((state) => state.cinemas);
  const { cinemasLoading, allCinemas } = cinemas;
  // const heroSlider = {
  //   arrows: false,
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   speed: 2000,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   responsive: [
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         arrows: false,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const history = useHistory();
  // const [cinemaDetails, setCinemaDetails] = useState(false);
  // if (cinemaDetails === true) {
  //   history.push("/cinema-details");
  // }
  // useEffect(() => {
  //   if (!cinemas?.allCinemas) {
  //     dispatch(fetchAllCinemas());
  //   }
  //   window.scrollTo(0, 0);
  // }, []);
  useEffect(() => {
    dispatch(fetchAllCinemas());
  }, [dispatch]);
  useEffect(() => window.scrollTo(0, 0), []);
  const heroSectionObj = {
    arr: [1, 2],
    backgroundImg: cinemaBg,
    title: t("cinema_listing.maintitle"),
    msg: t("cinema_listing.maindesc"),
  };
  return cinemasLoading ? (
    <section className="container-fluid">
      <div className="row my-5">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    </section>
  ) : allCinemas?.length > 0 ? (
    <div>
      <div className="px-0">
        {/* Hero Section */}
        {/* <Slider {...heroSlider} className="">
          {[1, 2].map((banner, index) => (
            <section className="cinema-hero-section" key={index}>
              <div className="hero-img-section">
                <img src={cinemaBg} alt="Movie Banner" className="hero-img-1" />
              </div>
              <div className="hero-text justify-content-center">
                <div className="container-fluid container-xl">
                  <div className="row">
                    <div className="col-10 col-sm-8 col-lg-6 mx-auto text-center">
                      <p className="title">Cinemas</p>
                      <p className="msg">
                        We have the biggest cinema loyalty scheme, we - offering
                        guests the chance to see all the films they want, as
                        often as they like.
                      </p>
                      <div className="d-flex justify-content-center">
                        <img src={whiteArrowDown} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
        </Slider> */}
        <AncilliaryHeroSection sectionObj={heroSectionObj} />

        <section className="container-fluid container-xl mt-3">
          <div className="row">
            {allCinemas?.map((cinema, index) => (
              // <AuditoriumCard
              //   imgSrc={cinema}
              //   name="Capital Theatre"
              //   address="London, 13 Stamford Street"
              //   sideIcon={map}
              //   clickFunc={cinemaDetails}
              // />
              <motion.div
                key={index}
                className="col-6 col-sm-4"
                initial={{ x: -75, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.75 }}
              >
                <div
                  className="cinema-card"
                  onClick={() => {
                    dispatch(selectedCinema(cinema.cinema_id, history));
                  }}
                >
                  <div className="img-wrapper">
                    <img src={cinema.cine_image_url} alt="" />
                  </div>
                  <div className="">
                    <div className="detail-row">
                      <p className="cinema-name">{cinema.cinema_name}</p>
                      <img src={map} className="ml-2 ml-sm-3" alt="" />
                    </div>
                    <div className="detail-row">
                      <p className="cinema-address">{cinema.cinema_address}</p>
                      {/* {feature && (
                        <p className="cinema-feature ml-2 ml-sm-3">{feature}</p>
                      )} */}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </section>
      </div>
    </div>
  ) : null;
};

export default withNamespaces()(CinemaListing);
