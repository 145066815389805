import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import close from "@assets/pictures/svgs/close.svg";
import copyLink from "@assets/pictures/svgs/copy-link.svg";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../../customHooks/useIsMobile";

function ShareTicketPopup({ t, ...props }) {
  const inputTextRef = useRef(null);
  const isMobile = useIsMobile();
  const [linkCopied, setLinkCopied] = useState(false);

  const [shareOnFacebook] = useState(true);
  const [shareOnTwitter] = useState(false);
  const [shareOnWhatsApp] = useState(true);
  const [shareOnSignal] = useState(false);

  const onClickCopy = () => {
    inputTextRef.current.select();
    inputTextRef.current.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setLinkCopied(true);
  };

  let final_message = encodeURIComponent(
    `${props.message}. View Ticket at ${props.link}`
  );

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="share-ticket-popup"
      size="lg"
      centered
      onHide={() => props.onHide()}
    >
      <section className="wrapper container-fluid">
        <img
          src={close}
          className="close-btn-main"
          alt=""
          onClick={() => props.onHide()}
        />
        <div className="text-center title-row">
          <p className="title">
            {t("Share")} {props.sharedObject}
          </p>
        </div>
        <div className="copy-link-row">
          {linkCopied && (
            <div className="link-copied-text">
              <span>{t("Link Copied")}</span>
            </div>
          )}
          <div>
            <input
              type="text"
              className="form-control copy-link-input"
              ref={inputTextRef}
              value={props.link}
            />
            <div
              className="d-flex align-items-center"
              onClick={() => onClickCopy()}
              role="button"
            >
              <p className="mr-2">{t("Copy")}</p>
              <img src={copyLink} alt="copy-link" width="20px" />
            </div>
          </div>
        </div>
        <div className="share-social-btn">
          {
            shareOnFacebook &&
            (
              <div>
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                        props.link
                    )}&quote=${encodeURIComponent(props.message)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <button className="social-btn fb-btn">
                    {t("Share on Facebook")}
                  </button>
                </a>
              </div>
            )
          }
          { shareOnTwitter &&
            (
              <div>
                <a
                    href={`https://twitter.com/intent/tweet?text=${final_message}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <button className="social-btn twitter-btn">
                    {t("Share on Twitter")}
                  </button>
                </a>
              </div>
            )
          }
          {
            (shareOnWhatsApp || !!isMobile) &&
            (
              <div>
                <a
                  href={`https://api.whatsapp.com/send?text=${final_message}`}
                  // href={`whatsapp://send?text=${encodeURIComponent(`${props.message}. View Ticket at ${props.link}`)}`}
                  data-action="share/whatsapp/share"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="social-btn whatsapp-btn">
                    {t("Share on WhatsApp")}
                  </button>
                </a>
              </div>
            )
          }

          {
            // as of now, Signal currently not offers an URL scheme to send messages.
            shareOnSignal &&
            (
              <div>
                <button className="social-btn signal-btn">Share on Signal</button>
              </div>
            )
          }
        </div>
      </section>
    </Modal>
  );
}

export default withNamespaces()(ShareTicketPopup);
