export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";
export const FETCH_FNB_ITEMS = "FETCH_FNB_ITEMS";
export const FETCH_FNB_ITEMS_ERROR = "FETCH_FNB_ITEMS_ERROR";
export const SELECT_FNB_ITEM = "SELECT_FNB_ITEM";
export const FETCH_MODIFIER_GROUP = "FETCH_MODIFIER_GROUP";
export const FETCH_MODIFIER_GROUP_ERROR = "FETCH_MODIFIER_GROUP_ERROR";
export const FETCH_MODIFIFERS = "FETCH_MODIFIFERS";
export const FETCH_MODIFIERS_ERROR = "FETCH_MODIFIERS_ERROR";
export const CLOSE_MODIFIER_POPUP = "CLOSE_MODIFIER_POPUP";
export const SELECT_MODIFIER = "SELECT_MODIFIER";
export const FNB_RESERVATION_DONE = "FNB_RESERVATION_DONE";
export const SET_RECIPE_ITEMS = "SET_RECIPE_ITEMS";
export const SELECT_MODIFIER_UNDER_RECIPES = "SELECT_MODIFIER_UNDER_RECIPES";
export const CLOSE_RECIPE_MODIFIER_POPUP = "CLOSE_RECIPE_MODIFIER_POPUP";
export const REMOVE_FNB_ITEM = "REMOVE_FNB_ITEM";
export const SET_TAX_PERCENT_FNB = "SET_TAX_PERCENT_FNB";
export const SET_FNB_BOOKING_DETAILS = "SET_FNB_BOOKING_DETAILS";
export const SET_FT_ID = "SET_FT_ID";
export const SET_FNB_LOADER = "SET_FNB_LOADER";
export const CLEAR_SELECTED_FNB_ITEMS = "CLEAR_SELECTED_FNB_ITEMS";
export const CLEAR_FNB_STATE = "CLEAR_FNB_STATE";
export const FNB_BOOKING_ERROR = "FNB_BOOKING_ERROR";
export const UPDATE_FNB_DATA_FROM_BACKEND = "UPDATE_FNB_DATA_FROM_BACKEND";
