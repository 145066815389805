import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import homeIcon from "@assets/pictures/svgs/home.svg";
import activehomeIcon from "@assets/pictures/svgs/home-active.svg";
import moviesIcon from "@assets/pictures/svgs/movies.svg";
import activeMoviesIcon from "@assets/pictures/svgs/movies-active.svg";
// import transactionIcon from "@assets/pictures/svgs/transaction.svg";
import transactionIcon from "@assets/pictures/svgs/firework.svg";
// import activeTransactionIcon from "@assets/pictures/svgs/transaction-active.svg";
import activeTransactionIcon from "@assets/pictures/svgs/firework-active.svg";
import profileIcon from "@assets/pictures/svgs/profile1.svg";
import activeProfileIcon from "@assets/pictures/svgs/profile1-active.svg";
import { motion } from "framer-motion";
export default function MobileBottomMenu(props) {
  const history = useHistory();
  let activeIcon = null;
  const { pathname } = useLocation();
  if (pathname === "/") {
    activeIcon = "home";
  } else if (pathname === "/schedules") {
    activeIcon = "schedules";
  } else if (pathname === "/mini-festivals") {
    activeIcon = "pno";
  } else if (pathname === "/menu") {
    activeIcon = "menu";
  }
  return (
    activeIcon && (
      <section className="mobile-bottom-menu d-block d-md-none">
        <div className="d-flex justify-content-around align-items-center h-100">
          <div
            className="menu-icon"
            onClick={() => {
              if (window.location.pathname === `/mini-festivals`) {
                history.push(`/`);
                // window.location.reload();
              } else {
                history.push(`/`);
              }
            }}
          >
            <img
              src={activeIcon !== "home" ? homeIcon : activehomeIcon}
              alt="home"
            />
          </div>
          <div
            className="menu-icon"
            onClick={() => {
              history.push("/schedules");
            }}
          >
            <img
              src={activeIcon !== "schedules" ? moviesIcon : activeMoviesIcon}
              alt="schedules"
            />
          </div>
          <div
            className="menu-icon fest"
            onClick={() => {
              history.push("/mini-festivals");
            }}
          >
            <img
              src={
                activeIcon !== "pno" ? transactionIcon : activeTransactionIcon
              }
              alt="Mini Festivals"
            />
          </div>
          <div
            className="menu-icon"
            onClick={() => {
              history.push("/menu");
            }}
          >
            <img
              src={activeIcon !== "menu" ? profileIcon : activeProfileIcon}
              alt="home"
            />
          </div>
        </div>
      </section>
    )
  );
}
