import React from "react";

import crowd from "@assets/pictures/svgs/crowd.svg";
import ticketTwo from "@assets/pictures/svgs/ticket-two.svg";
import diamond from "@assets/pictures/svgs/diamond.svg";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { withNamespaces } from "react-i18next";
import { currencyFormatter } from "../../helper/currencyFormatter";
const ScreeningCard = (props) => {
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const t = props.t;
  let imgSrc = crowd;
  let card = "basic";
  let name = "Crowdsourced Screening";
  let description =
    "This screening will get confirmed when atleast 25 seats have been booked.";
  let price = `Starting ${curr_code} 25`;

  if (props.screen.booking_type_id === 2) {
    imgSrc = crowd;
    card = "basic";
    name = t("Crowdsourced Screening");
    description = `${t("This screening will get confirmed when atleast")} ${
      props.screen.min_seats
    } ${t("seats have been booked")}`;
    price = `${t("Starting")} ${curr_code} ${currencyFormatter(
      props.screen.amount
    )}`;
  } else if (props.screen.booking_type_id === 1) {
    imgSrc = ticketTwo;
    card = "regular";
    name = t("Confirmed Screening");
    description = `${t(
      "No need to wait for the minimum no. of seats to sell. Book"
    )} ${props.screen.min_seats} ${t("seats and confirm immediately")}`;
    price = `${t("Starting")} ${curr_code} ${currencyFormatter(
      props.screen.amount
    )}`;
  } else if (props.screen.booking_type_id === 3) {
    imgSrc = diamond;
    card = "premium";
    name = t("Private Screening");
    description = t(
      "Book an exclusive private screening for your friends and family"
    );
    price = `${t("Starting")} ${curr_code} ${currencyFormatter(
      props.screen.amount
    )}`;
  }

  return (
    <motion.div
      className="mb-4 mb-lg-0 col-md-6 mx-auto col-lg-4"
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="screening-parent">
        <div
          className={`screening ${card} ${
            !props.isUnconfirmedAvailable &&
            props.screen.booking_type_id === 2 &&
            "inactive"
          }`}
        >
          <div className="upper">
            <div className="icon-wrapper">
              <img src={imgSrc} alt="" />
            </div>
            <p className="screening-name">{name}</p>
          </div>
          <div className="lower">
            <p className="description">{description}</p>
            <p className="price">{price}</p>
            <button
              id="forStageThree"
              className={`btn-main ${
                !props.isUnconfirmedAvailable &&
                props.screen.booking_type_id === 2 &&
                "inactive"
              }`}
              onClick={() => props.onClick(props.screen.booking_type_id)}
            >
              {t("common.Select")}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default withNamespaces()(ScreeningCard);
