import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  FETCH_USER_HISTORY,
  FETCH_USER_HISTORY_SUCCESS,
  FETCH_USER_HISTORY_ERROR,
  LOGOUT,
  UPDATE_USER_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  OTP_ERROR,
  OTP_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS_ERROR,
  LOGIN_VIA_OTP_SUCCESS,
  LOGIN_VIA_OTP_ERROR,
  START_OTP_TIMER,
  STOP_OTP_TIMER,
  RESET_OTP_TIMER,
  CONTACTUSMAIL,
  SHOW_LOGIN_POPUP,
} from "./authTypes";

let INITIAL_MINS = 0;
let INITIAL_SECONDS = 30;

let initialState = {
  loggedInUser: JSON.parse(localStorage.getItem("user") || null) || null,
  loginError: null,
  registerError: null,
  userHistory: [],
  userHistorySuccess: false,
  userHistoryError: null,
  forgotPasswordUserDetails: null,
  forgotPasswordError: null,
  otpSuccess: null,
  otpError: null,
  resetPasswordSuccess: null,
  resetPasswordError: null,
  loginOtpSuccess: null,
  loginOtpError: null,
  timer: {
    minutes: INITIAL_MINS,
    seconds: INITIAL_SECONDS,
  },
  timerCompleted: false,
  mail_data: null,
  showLoginPopup: false,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_VIA_OTP_SUCCESS:
      return {
        ...state,
        loginOtpSuccess: payload,
        loginOtpError: null,
      };
    case LOGIN_VIA_OTP_ERROR:
      return { ...state, loginOtpError: payload };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: payload,
        resetPasswordError: null,
      };
    case RESET_PASSWORD_SUCCESS_ERROR:
      return { ...state, resetPasswordError: payload };
    case OTP_SUCCESS:
      return { ...state, otpSuccess: payload, otpError: null };
    case OTP_ERROR:
      return { ...state, otpError: payload };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordUserDetails: payload,
        forgotPasswordError: null,
      };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, forgotPasswordError: payload };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        loggedInUser: null,
        loginError: null,
        registerError: null,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loggedInUser: payload,
      };
    case LOGOUT:
      return {
        ...state,
        loggedInUser: null,
      };
    case LOGIN_ERROR:
      return { ...state, loggedInUser: null, loginError: payload };
    case REGISTER_ERROR:
      return { ...state, loggedInUser: null, registerError: payload };
    case FETCH_USER_HISTORY:
      return {
        ...state,
        userHistory: [],
        userHistorySuccess: true,
      };
    case FETCH_USER_HISTORY_SUCCESS:
      return {
        ...state,
        userHistory: payload,
        userHistorySuccess: false,
      };
    case FETCH_USER_HISTORY_ERROR:
      return { ...state, userHistory: [], userHistoryError: payload };
    case START_OTP_TIMER: {
      let oldTimer = state.timer;
      let newTimer = {};
      let newSeconds = oldTimer.seconds;
      let newMinutes = oldTimer.minutes;
      let timerCompleted = false;

      if (newSeconds === 0) {
        newSeconds = 59;
        if (newMinutes === 0) {
          // timer has ended
          timerCompleted = true;
          newSeconds = 0;
          newMinutes = 0;
          if (action.payload.timerInterval) {
            clearInterval(action.payload.timerInterval);
          }
        } else {
          newMinutes -= 1;
        }
      } else {
        newSeconds -= 1;
      }

      newTimer = {
        seconds: newSeconds,
        minutes: newMinutes,
      };

      return {
        ...state,
        timer: newTimer,
        timerCompleted,
      };
    }
    case STOP_OTP_TIMER:
      // if (action.payload && action.payload.timerInterval) {
      //   clearInterval(action.payload.timerInterval);
      // }
      return { ...state };
    // return{...state,
    // }
    case RESET_OTP_TIMER: {
      return {
        ...state,
        timer: {
          seconds: INITIAL_SECONDS,
          minutes: INITIAL_MINS,
        },
        timerCompleted: false,
      };
    }
    case CONTACTUSMAIL:
      return { ...state, mail_data: action.payload };
    case SHOW_LOGIN_POPUP: {
      return {
        ...state,
        showLoginPopup: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
