import React, { useState, useEffect } from 'react';

function useIsOnline() {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        setIsOnline(window.navigator.onLine);

        window.addEventListener('offline', function(e) { setIsOnline(false) });
        window.addEventListener('online', function(e) { setIsOnline(true) });
    }, []);

    return isOnline;
}

export default useIsOnline;