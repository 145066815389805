import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useSelector, useDispatch } from "react-redux";
import back from "@assets/pictures/svgs/back-modal.svg";
import { withNamespaces } from "react-i18next";
import { otpValidation } from "@helper/formatting";

import { verifyLoginOTP } from "../../store/auth/authActions";
// const handleChangeOtp = (value) => {
//   setOtp(value);
// };
const LoginOtp = (props) => {
  const { t, allowResendLogin, onClickResendLogin } = props;

  const dispatch = useDispatch();
  const [otp, setOtp] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const state = useSelector((state) => state);
  const {
    auth: {
      loginOtpSuccess,
      forgotPasswordError,
      forgotPasswordUserDetails,
      otpError,
      timerCompleted,
      timer: { seconds, minutes },
    },
  } = state;
  // useEffect(() => setInterval(() => setAllowResendLogin(true), 5000), []);
  const [step, setStep] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState(``);
  const onClickConfirm = () => {
    setSubmitted(true);
    if (!otp) return;
    setLoading(true);
    dispatch(
      verifyLoginOTP({
        otp,
        callback: setLoading,
        successCallback: () => props.onSuccess(),
      })
    );
  };

  return (
    <>
      {/* <section className="wrapper container-fluid">
      <img
        src={back}
        className="back-btn-main"
        onClick={() => {
          step > 1 ? setStep(step - 1) : props.closeFunc(false);
        }}
        alt=""
      /> */}
      <p className="title">{t("Verify OTP")}</p>
      <>
        <p className="msg mb-2">
          {t(`Enter OTP sent to`)} {loginOtpSuccess?.cust_email}{" "}
          {loginOtpSuccess?.cust_email !== "" ? t(`and`) : null}{" "}
          {loginOtpSuccess?.cust_mobile}
        </p>
        <div className="otp-input">
          {/* <OtpInput
            value={otp}
            onChange={(otp) => setOtp(otp)}
            numInputs={6}
            separator={<span></span>}
          /> */}
          <input
            type="text"
            value={otp}
            onChange={(e) => {
              if (otpValidation(e.target.value)) {
                setOtp(e.target.value);
              }
            }}
          />
        </div>
        {submitted && !otp && <p className="error">{t("field_required")}</p>}
      </>
      {minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}
      :
      {seconds.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}
      &nbsp;
      {allowResendLogin && (
        <>
          {" "}
          <div className="d-flex align-items-center justify-content-between mt-2">
            <p>{t(`otp_message`)}&nbsp;</p>
            <button
              onClick={onClickResendLogin}
              className="btn-basic ml-2"
              // className={`btn-main mx-auto ${!allowResendLogin && `inactive`}`}
            >
              {t(`Resend`)}
            </button>
          </div>
        </>
      )}
      {forgotPasswordError && <p className="error">{forgotPasswordError}</p>}
      {otpError && <p className="error">{otpError}</p>}
      <button
        className={`btn-main mt-4 ${loading && `loader-text`}`}
        onClick={onClickConfirm}
      >
        {step === 4 ? t(`Close`) : t(`login_form.Confirm`)}
      </button>
      {/* </section> */}
    </>
  );
};

export default withNamespaces()(LoginOtp);
