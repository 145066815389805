import {
  FETCH_SEAT_LAYOUT,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SEAT_TYPE,
  FETCH_SEAT_TYPE_SUCCESS,
  FETCH_SEAT_TYPE_ERROR,
  SET_SEAT_SELECTED,
  CLEAR_SEAT_SELECTION,
  UPDATE_SEAT_LAYOUT,
  RESERVE_SEAT,
  RESERVE_SEAT_SUCCESS,
  RESERVE_SEAT_ERROR,
  SET_RESERVATION_ID,
  RELEASE_SEAT,
  RELEASE_SEAT_SUCCESS,
  RELEASE_SEAT_ERROR,
  SET_TEMP_RESERVATION_ID,
  SET_INTERNET_HANDLING_FEES_APPLIED,
} from "./seatLayoutTypes";
import { groupBy } from "lodash";
import { getValueFromGlobalOptions } from "@helper/utils";

let initialState = {
  seatLayout: null,
  seatLayoutLoading: false,
  seatLayoutError: null,
  seatType: null,
  seatTypeLoading: false,
  seatTypeError: null,
  seats_selected: [],
  reservation_id: null,
  reservation_idLoading: false,
  reservation_idError: null,
  reservation_id_temp: null,
  is_internet_handling_enable: false,
};

const seatLayoutReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SEAT_LAYOUT:
      return {
        ...state,
        seatLayout: [],
        seatLayoutLoading: true,
      };
    case FETCH_SEAT_LAYOUT_SUCCESS: {
      // Seat layout sorting start
      let seatData = [];
      let seatData1 = groupBy(payload.seats.Records, "sst_seat_type");
      payload.seatType.Records = payload.seatType.Records.sort(
        (a, b) => a.sst_order - b.sst_order
      );
      // console.log('seatData1 :', seatData1);
      payload.seatType.Records.map((type) => {
        for (var key in seatData1) {
          if (seatData1.hasOwnProperty(key)) {
            // console.log(key + " -> " + seatData1[key]);
            if (key == type.sst_seat_type) {
              seatData[key] = seatData1[key];
            }
          }
        }
        // console.log('seatData :', seatData);
      });
      let tempArray = [];
      for (let key in seatData) {
        if (seatData.hasOwnProperty(key)) {
          let seat_price = 0;
          let seat_price_obj = seatData[key].filter((x) => {
            return x;
          });
          // console.log('seat_price_obj :', seat_price_obj);
          if (seat_price_obj) {
            seat_price = seat_price_obj[0].seat_price;
          }
          // console.log('seat_price :', seat_price);
          tempArray.push({
            seatType: key,
            seat_price: seat_price,
            seats: seatData[key],
          });
        }
      }
      // let finalTempArray = [];
      // tempArray.map(item=>{
      //   let tempArray1 = [];
      //   let newtempArray =groupBy(item,"")
      // })
      let finalArray = [];

      // [{
      //   seatType: 'gold',
      //   seats: []
      // }]

      for (let i = 0; i < tempArray.length; i++) {
        let singleSeatType = tempArray[i];

        let seatData_1 = groupBy(singleSeatType.seats, "sl_row_num");
        // seatData_1 = {
        //   0: [],
        //   1: []
        // }
        let tempArray_1 = [];
        for (let key in seatData_1) {
          if (seatData_1.hasOwnProperty(key)) {
            let seatData_2 = seatData_1[key].map((singleSeat) => {
              singleSeat.selectStatus = false;
              singleSeat.SelectStatusFlag = false;
              return singleSeat;
            });
            seatData_2 = seatData_2.sort(function (a, b) {
              return a.sl_col_num - b.sl_col_num;
            });

            tempArray_1.push(seatData_2);
          }
        }
        // console.log('tempArray_1 before', tempArray_1)

        // console.log('tempArray_1 after', tempArray_1)
        // tempArray_1 = [[], []]
        tempArray[i].seatsFinal = tempArray_1;
        // finalArray.push({ seatType: tempArray, seats: tempArray_1 })
      }
      // if (state.totalNumberOfSeats == 0) {
      //     vmVue.$root.$emit('bv::show::modal', 'seat_select_modal');
      // }
      // state.seatLayoutData = tempArray;
      // Seat layout sorting end
      return {
        ...state,
        seatLayout: tempArray,
        seatLayoutLoading: false,
      };
    }
    case FETCH_SEAT_LAYOUT_ERROR:
      return { ...state, seatLayout: null, seatLayoutError: payload };
    case FETCH_SEAT_TYPE:
      return {
        ...state,
        seatType: null,
        seatTypeLoading: true,
      };
    case FETCH_SEAT_TYPE_SUCCESS:
      return {
        ...state,
        seatType: payload,
        seatTypeLoading: false,
      };
    case FETCH_SEAT_TYPE_ERROR:
      return { ...state, seatType: null, seatTypeError: payload };
    case SET_SEAT_SELECTED:
      const {
        SeatTypes,
        numberOfSeats,
        category_id,
        booking_type_id = null,
      } = payload;
      const newSeatLayout = [...state.seats_selected];
      const existingSeat = state.seats_selected.some(
        (seat) =>
          seat.sl_seat_name === SeatTypes.sl_seat_name &&
          seat.sl_row_num === SeatTypes.sl_row_num &&
          seat.sl_col_num === SeatTypes.sl_col_num &&
          seat.sl_id === SeatTypes.sl_id &&
          seat.seat_reserve_type_id === SeatTypes.seat_reserve_type_id &&
          seat.srt_id === SeatTypes.srt_id
      );
      console.log(
        "🚀 ~ file: movieReducer.js ~ line 756 ~ movieReducer ~ category_id",
        category_id
      );
      // let taxAmount =
      //   SeatTypes.seat_price - SeatTypes.seat_net_price.toFixed(2);
      if (existingSeat) {
        let finalIndex = state.seats_selected.findIndex(
          (seat) =>
            seat.sl_seat_name === SeatTypes.sl_seat_name &&
            seat.sl_row_num === SeatTypes.sl_row_num &&
            seat.sl_col_num === SeatTypes.sl_col_num &&
            seat.sl_id === SeatTypes.sl_id &&
            seat.seat_reserve_type_id === SeatTypes.seat_reserve_type_id &&
            seat.srt_id === SeatTypes.srt_id
        );
        SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
        newSeatLayout.splice(finalIndex, 1);
        // state.selectedTicketPrice.priceInCents -= SeatTypes.seat_price;
        // state.selectedTicketPrice.taxInCents -= taxAmount;
      } else if (category_id == 2) {
        SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
        newSeatLayout.push(SeatTypes);
        // state.selectedTicketPrice.priceInCents += SeatTypes.seat_price;
        // state.selectedTicketPrice.taxInCents += taxAmount;
      } else if (
        category_id == 1 &&
        (booking_type_id == 2 || booking_type_id == 1)
      ) {
        // its a crowd source screening
        SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
        newSeatLayout.push(SeatTypes);
      }
      // else if (
      //   category_id != 2 &&
      //   numberOfSeats > state.seats_selected.length
      // ) {
      //   SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
      //   newSeatLayout.push(SeatTypes);
      // }
      return {
        ...state,
        seats_selected: newSeatLayout,
      };

    case CLEAR_SEAT_SELECTION:
      state.seats_selected.map((s) => (s.SeatSelected = !s.SeatSelected));
      return {
        ...state,
        seats_selected: [],
      };

    case UPDATE_SEAT_LAYOUT: {
      let newSeatLayout = [...state.seatLayout];
      let seats_selected = state.seats_selected;
      let { sl_id, status } = action.payload;
      let globalOptions = action.globalOptions;
      let isCovidSeatEnabled = getValueFromGlobalOptions(
        globalOptions,
        "ENABLE_COVID_SEAT_LAYOUT"
      );
      console.log("inside update seat layout reducer =>> ", action.payload);

      for (let seatType of newSeatLayout) {
        for (let [rowIndex, row] of seatType.seatsFinal.entries()) {
          for (let [seatIndex, seat] of row.entries()) {
            if (seat.sl_id == sl_id) {
              seat.seat_reserve_type_id = status;
              seat.covidBlockedTemp = false;

              if (isCovidSeatEnabled === "Y") {
                if (seatType.seatsFinal[rowIndex][seatIndex - 1]) {
                  let leftSeat = seatType.seatsFinal[rowIndex][seatIndex - 1];
                  let isNotSelected =
                    seats_selected.findIndex(
                      (x) => x.sl_id == leftSeat.sl_id
                    ) === -1;
                  if (
                    ![8, 2, 13].includes(leftSeat.seat_reserve_type_id) &&
                    isNotSelected
                  ) {
                    leftSeat.covidBlockedTemp =
                      status === 8
                        ? true
                        : seatType.seatsFinal[rowIndex][seatIndex - 2]
                            ?.seat_reserve_type_id === 8
                        ? true
                        : false;
                  } else if (leftSeat.seat_reserve_type_id == 8) {
                    leftSeat.covidBlockedTemp = false;
                    seat.covidBlockedTemp = status === 1 ? true : false;
                  }
                }

                if (seatType.seatsFinal[rowIndex][seatIndex + 1]) {
                  let rightSeat = seatType.seatsFinal[rowIndex][seatIndex + 1];
                  if (
                    ![8, 2, 13].includes(rightSeat.seat_reserve_type_id) &&
                    seats_selected.findIndex(
                      (x) => x.sl_id == rightSeat.sl_id
                    ) === -1
                  ) {
                    rightSeat.covidBlockedTemp =
                      status === 8
                        ? true
                        : seatType.seatsFinal[rowIndex][seatIndex + 2]
                            ?.seat_reserve_type_id === 8
                        ? true
                        : false;
                  } else if (rightSeat.seat_reserve_type_id == 8) {
                    rightSeat.covidBlockedTemp = false;
                    seat.covidBlockedTemp = status === 1 ? true : false;
                  }
                }
              }
            }
          }
        }
      }

      /* 
            row[seatIndex - 1].seat_reserve_type_id !== 8 &&
                  row[seatIndex - 1].seat_reserve_type_id !== 2 &&
                  row[seatIndex - 1].seat_reserve_type_id !== 13
            */

      return {
        ...state,
        seatLayout: newSeatLayout,
      };
    }
    case RESERVE_SEAT:
      return {
        ...state,
        reservation_id: null,
        reservation_idLoading: true,
      };
    case RESERVE_SEAT_ERROR:
      return {
        ...state,
        reservation_id: null,
        reservation_idError: payload,
      };
    case RESERVE_SEAT_SUCCESS:
      return {
        ...state,
        reservation_id: payload,
        reservation_idLoading: false,
      };
    case SET_RESERVATION_ID:
      return {
        ...state,
        reservation_id: payload,
        reservation_idLoading: false,
      };
    case RELEASE_SEAT_SUCCESS:
      return {
        ...state,
        reservation_id: null,
      };
    case SET_TEMP_RESERVATION_ID: {
      return {
        ...state,
        reservation_id_temp: payload,
      };
    }
    case SET_INTERNET_HANDLING_FEES_APPLIED: {
      return {
        ...state,
        is_internet_handling_enable: payload,
      };
    }
    default:
      return state;
  }
};

export default seatLayoutReducer;
