import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';

function ErrorPopup({ show, action, title, subtitle, buttonText, showEmoji=true }) {
    return (
        <Modal
          show={show}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="disclaimer-popup"
          size="md"
          backdrop="static"
        >
          <Modal.Header className="position-relative flex-column" closeButton>
            <h2 className="title w-100">{title}</h2>
            {showEmoji && <p className="mx-auto frs-32">&#128577;</p>}
          </Modal.Header>
          
          <Modal.Body>
            <div className="message border-grey-botto">
              <p className="px-md-5">{subtitle}</p>
            </div>
              <button className={`btn blue-btn px-5 mt-4 btn-center`}
                onClick={action}>
                {buttonText}
              </button>
          </Modal.Body>
        </Modal>
    )
}

export default ErrorPopup;