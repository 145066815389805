import Api from "./Api";

export default {
  GetCinemas(payload) {
    if (payload) {
      const { location } = payload;
      return Api().post(
        `/api/external/cinemas/?&location=${location}`,
        payload
      );
    }
    return Api().post(`/api/external/cinemas/`, {});
  },
  GetCMSData(payload) {
    return Api().get(`/api/external/cms/${payload.cinema_id}`);
  },
};
