import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { motion } from "framer-motion";
import { withNamespaces } from "react-i18next";

function LoadMoreButton({ show = false, onClick = () => {}, t } = {}) {
  const [loader, setLoader] = useState(false);

  return show ? (
    <div
      className="col-12 text-center mt-1"
      onClick={() => {
        setLoader(true);
        onClick({ callback: setLoader });
      }}
    >
      {loader ? (
        // <div className="row justify-content-center w-100">
        <Spinner animation="border" />
      ) : (
        // </div>
        <motion.button
          className="btn-main mx-auto"
          id="ms-btn"
          initial={{ y: -25, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.25 }}
        >
          {t("common.Load More")}
        </motion.button>
      )}
    </div>
  ) : null;
}

export default withNamespaces()(LoadMoreButton);
