import CinemaService from "@apiService/cinemaService";
import {
  ADD_ALL_CINEMAS,
  FETCH_ALL_CINEMAS,
  SET_SELECTED_CINEMA,
} from "./cinemasTypes";

const setAllCinemas = (payload) => ({
  type: ADD_ALL_CINEMAS,
  payload,
});

export const fetchAllCinemas = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ALL_CINEMAS });
    const { data } = await CinemaService.GetCinemas();
    if (data?.status) {
      dispatch({ type: ADD_ALL_CINEMAS, payload: data.Records });
    }
  } catch (error) {
    console.error(error);
  }
};

export const selectedCinema = (selectedCinemaId, history) => (
  dispatch,
  getState
) => {
  console.log(`here`);
  try {
    const {
      cinemas: { allCinemas },
    } = getState();
    const cinema = allCinemas.find(
      (cinema) => cinema.cinema_id === selectedCinemaId
    );
    dispatch({ type: SET_SELECTED_CINEMA, payload: cinema });
    history.push(`/cinema-details/${selectedCinemaId}`);
  } catch (error) {
    console.error(error);
  }
};
