import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";

import { globalConfigContext } from "@context/GlobalConfigContext";

function Page404({ props, t }) {
  const { arabic } = useContext(globalConfigContext);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container-fluid header-space page-404">
        <p>404</p>
        <p>Sorry, The page you requested was not found</p>
        <button className="btn-main mx-auto" onClick={history.push("/")}>
          Go Home
        </button>
      </div>
    </>
  );
}

export default withNamespaces()(Page404);
