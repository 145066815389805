import React, { useEffect, useState } from "react";

import crowd from "@assets/pictures/svgs/crowd.svg";
import ticketTwo from "@assets/pictures/svgs/ticket-two.svg";
import diamond from "@assets/pictures/svgs/diamond.svg";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { withNamespaces } from "react-i18next";
import { currencyFormatter } from "../../helper/currencyFormatter";
import useIsMobile from "../../customHooks/useIsMobile";

const ScreeningCardNew = (props) => {
  const isMobile = useIsMobile();
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const { t, setCardSelected, cardSelected } = props;

  // let imgSrc = crowd;
  // let card = "basic";
  // let name = "Crowdsourced Screening";
  // let description =
  //   "This screening will get confirmed when atleast 25 seats have been booked.";
  // let price = `Starting ${curr_code} 25`;

  // if (props.screen.booking_type_id === 2) {
  //   imgSrc = crowd;
  //   card = "basic";
  //   name = t("Crowdsourced Screening");
  //   description = `${t("This screening will get confirmed when atleast")} ${
  //     props.screen.min_seats
  //   } ${t("seats have been booked")}`;
  //   price = `${t("Starting")} ${curr_code} ${props.screen.amount}`;
  // } else if (props.screen.booking_type_id === 1) {
  //   imgSrc = ticketTwo;
  //   card = "regular";
  //   name = t("Confirmed Screening");
  //   description = `${t(
  //     "No need to wait for the minimum no. of seats to sell. Book"
  //   )} ${props.screen.min_seats} ${t("seats and confirm immediately")}`;
  //   price = `${t("Starting")} ${curr_code} ${props.screen.amount}`;
  // } else if (props.screen.booking_type_id === 3) {
  //   imgSrc = diamond;
  //   card = "premium";
  //   name = t("Private Screening");
  //   description = t(
  //     "Book an exclusive private screening for your friends and family"
  //   );
  //   price = `${t("Starting")} ${curr_code} ${props.screen.amount}`;
  // }

  // useEffect(() => {
  //   const scrollElement = document.querySelector("#scrollElement");
  //   if (scrollElement) {
  //     scrollElement.scrollIntoView();
  //   }
  // });

  // useEffect(() => {
  //   props.screeningTypes[0].name = t("Crowdsourced Screening");
  //   props.screeningTypes[0].desc = `${t(
  //     "This screening will get confirmed when atleast"
  //   )} ${props.screeningTypes[0].screen.min_seats} ${t(
  //     "seats have been booked"
  //   )}`;
  //   props.screeningTypes[1].name = t("Confirmed Screening");
  //   props.screeningTypes[1].desc = `${t(
  //     "No need to wait for the minimum no. of seats to sell. Book"
  //   )} ${props.screeningTypes[0].screen.min_seats} ${t(
  //     "seats and confirm immediately"
  //   )}`;
  //   props.screeningTypes[2].name = t("Private Screening");
  //   props.screeningTypes[2].desc = t(
  //     "Book an exclusive private screening for your friends and family"
  //   );
  // });

  function assigner(screenNum, type) {
    if (type === "name") {
      if (screenNum.booking_type_id === 2) {
        return t("Crowdsourced Screening");
      } else if (screenNum.booking_type_id === 1) {
        return t("Confirmed Screening");
      } else if (screenNum.booking_type_id === 3) {
        return t("Private Screening");
      } else {
        return t("Confirmed Screening");
      }
    } else if (type === "desc") {
      if (screenNum.booking_type_id === 2) {
        return `${t("This screening will get confirmed when atleast")} ${
          screenNum.min_seats
        } ${t("seats have been booked")}`;
      } else if (screenNum.booking_type_id === 1) {
        return `${t(
          "No need to wait for the minimum no. of seats to sell. Book"
        )} ${screenNum.min_seats} ${t("seats and confirm immediately")}`;
      } else if (screenNum.booking_type_id === 3) {
        return `${t(
          "Book an exclusive private screening for your friends and family"
        )}`;
      } else {
        return `${t(
          "No need to wait for the minimum no. of seats to sell. Book"
        )} ${"25"} ${t("seats and confirm immediately")}`;
      }
    }
  }
  console.log("props?.screeningTypes :>> ", props?.screeningTypes);
  return (
    <motion.section
      className="row"
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="col-12">
        <div className="screening-type-2">
          <div className="d-md-inline-flex justify-content-center">
            {props?.screeningTypes?.map(
              (screen, index) =>
                screen?.isAvailable &&
                (!isMobile ? (
                  <article
                    key={index}
                    className={`mb-4 ${
                      screen?.booking_type_id === 2
                        ? "green"
                        : screen?.booking_type_id === 3
                        ? "yellow"
                        : ""
                    }`}
                    id={``}
                  >
                    <div className="mb-4">
                      {/* <p className="popular">
                        {screen?.booking_type_id === 2 && t(`POPULAR`)}
                      </p> */}
                      <p className="screen-name">{assigner(screen, "name")}</p>
                      {screen?.booking_type_id === 3
                        ? screen.screen_total_seats && (
                            <p className="audi-cap">
                              {t("Auditorium Capacity")} -{" "}
                              {screen.screen_total_seats}
                            </p>
                          )
                        : screen?.booking_type_id === 2 && (
                            <p className="audi-cap">{t(`POPULAR`)}</p>
                          )}
                    </div>
                    <div className="">
                      {/* <p className="around">{t("Around")}</p> */}
                      <p className="around-price">
                        {curr_code} {currencyFormatter(screen?.amount)}
                      </p>
                    </div>
                    <p className="screen-desc">{assigner(screen, "desc")}</p>

                    <button
                      id="forStageThree"
                      className={`btn-main btn-main-lg mx-auto ${
                        cardSelected && "inactive"
                      }`}
                      onClick={() => {
                        setCardSelected(true);
                        props.passedFunc(screen.booking_type_id);
                      }}
                    >
                      {t("common.Select")}
                    </button>
                  </article>
                ) : (
                  <section
                    className={`${
                      screen?.booking_type_id === 2
                        ? "green"
                        : screen?.booking_type_id === 3
                        ? "yellow"
                        : ""
                    }`}
                  >
                    <div className="p-2">
                      <div className="d-flex align-items-center justify-content-center flex-column">
                        <p className="screen_name">
                          {assigner(screen, "name")}&nbsp;
                          {screen?.booking_type_id === 3 &&
                            screen.screen_total_seats && (
                              <>
                                <p className="audi-cap">
                                  {t("Auditorium Capacity")} -{" "}
                                  {screen.screen_total_seats}
                                </p>
                              </>
                            )}
                          {/* {screen?.booking_type_id === 2 && t(`(Popular)`)} */}
                        </p>
                        <div className="">
                          {/* <p className="around">{t("Around")}</p> */}
                          <p className="around-price">
                            {curr_code} {currencyFormatter(screen?.amount)}
                          </p>
                        </div>
                      </div>
                      <p className="screen-desc">{assigner(screen, "desc")}</p>
                    </div>
                    <button
                      className={`btn-main w-100 ${cardSelected && "inactive"}`}
                      onClick={() => {
                        setCardSelected(true);
                        props.passedFunc(screen.booking_type_id);
                      }}
                    >
                      {t("common.Select").toUpperCase()}
                    </button>
                  </section>
                ))
            )}
          </div>
        </div>
      </div>
    </motion.section>
  );
};
export default withNamespaces()(ScreeningCardNew);
