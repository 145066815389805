import React from "react";
import {
  Row,
  Nav,
  Tabs,
  Tab,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
  Button,
} from "react-bootstrap";

import redCross from "@assets/pictures/svgs/red-cross.svg";
import currencyIcon from "@assets/pictures/svgs/currency-circle-icon.svg";

export default function Notifications(props) {
  //Start Edit Account

  return (
    <>
      <div className="notifications">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
          <div className="box">
            <div className="notify-content">
              <div className="notify-img">
                <img src={currencyIcon} alt="notification-icon" />
              </div>
              <div className="notify-desc">
                <p className="text">
                  Hello! We’ve just credited $10 back to your account as the
                  special screening for “Pulp Fiction” got canceled. You can
                  expect this to reflect in 3-5 working days.
                </p>
                <p className="time-text">2:41pm</p>
              </div>
            </div>
            <div className="notify-delete">
              <img src={redCross} className="delete-icon" />
            </div>
          </div>
        ))}
      </div>
      <div className="close-account text-right py-3 px-4">
        <span className="text-primary font-weight-bold">
          Close all notification
        </span>
      </div>
    </>
  );
}
