import { SET_SHOW_LOGIN_POPUP } from "./configTypes"

let initialState = {
    showLoginPopup: false,
}

const configReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SHOW_LOGIN_POPUP:
            return {
                ...state,
                showLoginPopup: action.payload,
            }
        default: return state;
    }
}

export default configReducer;